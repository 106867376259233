<script lang="ts" setup>
const { t } = useT();
const router = useRouter();
const { lastGames, yourPick } = useHomePage({ immediate: false });

const activeTab = ref("");

const goToPage = (link: string) => {
	router.push(link);
};

const handleClick = (key: string) => {
	activeTab.value = key;
};

onMounted(() => {
	handleClick(lastGames.value && lastGames.value?.length ? "last" : "recent");
});
</script>

<template>
	<div class="wrapper">
		<div class="top">
			<AText variant="tianshui" class="text-cannes title" :modifiers="['bold']">
				{{ t("Your games") }}
			</AText>
		</div>
		<nav ref="navigationContainer" class="game-navigation">
			<AButton
				v-if="lastGames && lastGames.length"
				variant="navigation"
				:class="{ active: activeTab === 'last' }"
				@click="handleClick('last')"
			>
				<NuxtIcon name="48/recent-games" filled />
				<AText variant="toledo" :modifiers="['semibold']">{{ t("Recently Played") }}</AText>
			</AButton>
			<AButton
				v-if="yourPick && yourPick.length"
				variant="navigation"
				:class="{ active: activeTab === 'recent' }"
				@click="handleClick('recent')"
			>
				<NuxtIcon name="48/favorites-games" filled />
				<AText variant="toledo" :modifiers="['semibold']">{{ t("Favorites") }}</AText>
			</AButton>
		</nav>

		<OGames :games="activeTab === 'last' ? lastGames : yourPick" :rows="2" />

		<div class="action">
			<AButton variant="secondary" class="btn" size="lg" @click="goToPage(`issues/all-games/`)">
				<AText variant="toledo" :modifiers="['medium']">
					{{ t("See more Games") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.wrapper {
	width: 100%;
}
.top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.title {
	@include media-breakpoint-down(md) {
		font-size: 48px;
	}
}

.game-navigation {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	margin: 32px 0;
	gap: gutter(1);
	padding: 4px 4px 8px;
	width: 100%;
	overflow-y: auto;

	@include media-breakpoint-down(sm) {
		margin: 22px 0;
	}
	@keyframes pulse {
		0% {
			width: 0;
			height: 0;
		}
		100% {
			width: 100%;
			height: 48px;
		}
	}
	button {
		min-width: max-content;
		height: 48px;
		border-width: 2px;
		flex-direction: row;
		gap: 8px;
		padding: 0 16px;
		border-radius: 999px;

		& > * {
			position: relative;
			z-index: 2;
		}

		&::before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			border-radius: inherit;
			background: var(--carletonville);
		}

		&.active {
			background: var(--celaya);
			position: relative;
			&:hover {
				opacity: 1;
			}

			&::before {
				opacity: 1 !important;
				animation: pulse 0.25s ease-out forwards;
			}
			&:deep(svg) {
				path {
					fill: var(--сirebon);
				}
			}
		}

		:deep(svg) {
			font-size: 24px;
			margin: 0;
		}
	}
	&::-webkit-scrollbar,
	&::-webkit-scrollbar-thumb {
		width: 0;
		height: 0;
	}
}

.action {
	width: 100%;
	position: relative;
	margin-top: gutter(4);
}
</style>
